/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function Nav() {
  return (
    <Disclosure as="nav" className="bg-blue-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center">
                {/* <div className="flex-shrink-0">
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="https://www.nznews.one/favicon.ico"
                    alt="https://www.nznews.one/favicon.ico"
                  />
                </div> */}
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <a
                      href="#"
                      className="rounded-md px-3 py-2 bg-gray-900 text-sm font-medium text-white"
                    >
                      实时热点新闻
                    </a>
                    <a
                      href="https://forum.nznews.one"
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      润新西兰社区
                    </a>
                    <a
                      href="mailto:nznews.one@gmail.com"
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      联系我
                    </a>
                  </div>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block"></div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as="a"
                href="#"
                className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
              >
                实时热点新闻
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="https://forum.nznews.one"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                target="_blank"
              >
                润新西兰社区
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="mailto:nznews.one@gmail.com"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                target="_blank"
              >
                联系我
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
