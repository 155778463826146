import React, { useEffect, useState } from "react";
import axios from "axios";
import Nav from "./Nav";

function App() {
  const [news, setNews] = useState<[]>();

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(
        "https://raw.githubusercontent.com/andyliu1985/nznews/main/translate1.json"
      );

      console.log(" news: -----", res.data);

      setNews(res.data ?? []);
    }

    fetchData();
  }, []);

  return (
    <div>
      <div className="">
        {/* <div className="text-xl mt-5">实时热点新闻</div>
        <a
          className="text-xl mt-5 ml-10 text-blue-500 hover:text-blue-800"
          href="https://forum.nznews.one"
        >
          润新西兰社区
        </a> */}
      </div>
      <Nav />

      <div className="container mx-auto">
        <div className="mx-5 lg:mx-1 mt-2 lg:mt-5 grid grid-rows-1 lg:grid-cols-2 lg:gap-2">
          {news
            ?.sort((a: any, b: any) => b.time - a.time)
            ?.map((newsItem: any, i) => (
              // currentNews = newsItem;
              // nextNews = news[i + 1] as any;

              <a
                className="mb-2 lg:my-4"
                key={i}
                href={newsItem.transLink}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex">
                  <div className="mr-4 flex-shrink-0 self-center">
                    <img
                      className="h-16 lg:h-28 w-24 lg:w-40"
                      src={newsItem.imageLink}
                      alt="新西兰新闻，新西兰热点"
                    ></img>
                  </div>
                  <div>
                    <h4 className="text-sm lg:text-lg font-bold text-indigo-400">
                      <div className="flex">
                        <span className="w-24">{newsItem.source}</span>
                        <span>
                          {new Date(newsItem.time).toLocaleDateString()}
                          &nbsp;&nbsp;
                          {new Date(newsItem.time).toLocaleTimeString()}
                        </span>
                      </div>
                    </h4>
                    <p className="mt-1 lg:mt-3 text-sm lg:text-xl font-bold">
                      {newsItem.transTitle}
                    </p>
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default App;
